<template>
<div class="device-item-box">
    <div class="left" @click="handleDeviceDetailClick">
        <div class="status status0" v-if="item.liveState === 0">进行</div>
        <div class="status status1" v-if="item.liveState === 1">停机</div>
        <div class="status status2" v-if="item.liveState === 2">故障</div>
        <div class="status status3" v-if="item.liveState === 3">维修</div>
        <div class="status status4" v-if="item.liveState === 4">保养</div>
        <img :src="imgUrl + item.photoUrl" alt="图片" v-if="item.photoUrl"/>
        <img src="../../assets/img/device/设备.png" alt="图片"  v-else/>
    </div>
    <div class="right">
        <div class="top">
            <div class="title" @click="handleDeviceDetailClick">
                <i class="iconfont iconmultilayer1"></i>
                <span style="
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            ">{{ item.deviceName }}</span>
            </div>
            <div class="love-btn">
                <i class="iconfont iconxingxing1" v-if="item.isFollow === 0" @click="handleFollowClick(item.id, 1)"></i>
                <i class="iconfont iconxingxing-copy" v-if="item.isFollow === 1" @click="handleFollowClick(item.id, 0)"></i>
            </div>
        </div>
        <div class="middle">
            <i class="iconfont iconleimupinleifenleileibie"></i>
            <span>{{item.deviceNo}}</span>

        </div>
        <div class="bottom">
            <div class="address single-row">
                <i class="iconfont iconzu"></i>
                <span>{{ item.groupName }}</span>
            </div>
            <!-- <div class="time single-row" style="margin-left: 0.2rem">{{item.runTime}}</div> -->
        </div>
    </div>
</div>
</template>

<script>
import {
    imgUrl
} from '@/config/env'
import {
    getChangeFollow
} from '@/api/deviceMonitor'

export default {
    name: 'DeviceItem',
    props: {
        item: {
            type: Object,
            default () {
                return {}
            },
        },
        index: {
            type: Number,
            default: 0,
        },
        deviceType: {
            type: Object,
            default () {
                return {}
            },
        },
    },
    data() {
        return {
            imgUrl: imgUrl,
        }
    },
    methods: {
        /**
         * 设备详情
         */
        handleDeviceDetailClick() {
            this.$router.push({
                path: '/deviceDetail',
                query: {
                    id: this.item.id,
                },
            })
        },
        /**
         * 关注
         */
        handleFollowClick(id, val) {
            getChangeFollow(id).then((res) => {
                this.$emit('handleUpdateDevice', {
                    index: this.index,
                    isFollow: val,
                })
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';

.device-item-box {
    @include wh(100%, 6rem);
    background-color: white;
    margin-top: 1rem;
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    justify-content: space-between;

    .left {
        @include wh(5rem, 100%);
        min-width: 5rem;
        background-color: #f1f1f1;
        position: relative;

        .status {
            @include wh(2rem, 1rem);
            text-align: center;
            line-height: 1rem;
            font-size: 0.6rem;
            background-color: #4acb04;
            color: white;
            position: absolute;
            left: 0;
            top: 0;

            &.status0 {
                background-color: #4acb04;
            }

            &.status1 {
                background-color: #e80204;
            }

            &.status2 {
                background-color: #fa7f00;
            }

            &.status3 {
                background-color: #fc4d0f;
            }

            &.status4 {
                background-color: #0264ed;
            }
        }

        img {
            @include wh(100%, 100%);
        }
    }

    .right {
        width: 0;
        flex: 1;
        height: 100%;
        box-sizing: border-box;
        padding-left: 0.71rem;

        .top {
            // @include wh(100%, 33.3%);

            // min-height: 33.3%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                display: flex;
                // align-items: center;
                flex: 1;

                i {
                    font-size: 0.8rem;
                    color: #4a8cff;
                    margin-right: 0.5rem;
                }

                span {
                    display: inline-block;
                    width: 0;
                    flex: 1;
                    color: black;
                    font-size: 0.7rem;
                    margin-top: 0.2rem;
                }
            }

            .love-btn {
                i {
                    color: #cccccc;
                    font-size: 1rem;
                }

                i.iconxingxing-copy {
                    color: #ff7747;
                }
            }
        }

        .middle {
            @include wh(100%, 33.3%);
            display: flex;
            align-items: center;

            i {
                font-size: 0.8rem;
                color: #4a8cff;
                margin-right: 0.5rem;
            }

            span {
                color: #999999;
                font-size: 0.65rem;
                margin-top: 0.2rem;
            }
        }

        .bottom {
            @include wh(100%, 33.3%);
            display: flex;
            justify-content: space-between;
            align-items: center;

            .address {
                display: flex;
                align-items: center;

                i {
                    font-size: 0.8rem;
                    color: #4a8cff;
                    margin-right: 0.5rem;
                }

                span {
                    color: #999999;
                    font-size: 0.65rem;
                    margin-top: 0.2rem;
                }
            }

            .time {
                color: #999999;
                font-size: 0.65rem;
                margin-top: 0.2rem;
            }
        }
    }
}
</style>
