<template>
  <!--区域-->
  <van-popup v-model="isShow" position="bottom" @click-overlay='onCancel'>
    <van-picker
      v-if="isShow"
      show-toolbar
      title="选择分组"
      confirm-button-text="确定"
      cancel-button-text="取消"
      :columns="columns"
      @cancel="onCancel"
      @confirm="onConfirm"
      @change="onChange"
    />
  </van-popup>
</template>

<script>
import Vue from 'vue'
import { Popup, Picker, Toast } from 'vant'
import 'vant/lib/popup/style'
import 'vant/lib/picker/style'
import 'vant/lib/toast/style'
Vue.use(Popup).use(Picker).use(Toast)

import { getLocatioinTrees, loadGroups } from '@/api/deviceMonitor'
export default {
  name: 'Area',
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showPopup(val) {
      console.log(val)
      if (val) {
        this.getLoadGroups()
      }
    },
  },
  computed: {
    isShow: {
      get() {
        return this.showPopup
      },
      set() {},
    },
  },
  data() {
    return {
      columns: [],
    }
  },
  methods: {
    /**
     * 确定选择区域
     */
    onCancel() {
      this.$emit('onCancel')
    },
    /**
     * 选择下一级区域
     */
    onConfirm(val, index) {
      if (val) {
        this.$emit('handleConfirmPicker', val)
      }
    },

    /**
     * 选择改变
     */
    onChange(picker, value, index) {
      // Toast(`当前选择值：${value.text}`)
    },
    /**
     * 分组数据
     */
    getLoadGroups() {
      loadGroups().then((res) => {
        let result = []
        res.details.content.map((item) => {
          result.push({
            text: item.groupName,
            id: item.id,
          })
        })
        this.columns = result
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
